<template>
    <span class="in-cart-quantity">
        <button class="border border-radius7 px-1 bg-none" @click="decrement()"
            :disabled="value.quantity == 1 || disabledDecrement">
            <b-spinner v-if="disabledDecrement" label="Spinning" small class="mb-1"></b-spinner>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-dash product-counter-icon"
                viewBox="0 0 16 16">
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
            </svg>
        </button>
        <button class="mx-2 cursor-pointer fontsize-medium text-color-666 quantity-btn" @click="newQuantity()" :disabled="disabledIncrement || disabledDecrement">{{value.quantity}}</button>
        <button class="border border-radius7 px-1 bg-none" @click="increment()" :disabled="disabledIncrement">
            <b-spinner v-if="disabledIncrement" label="Spinning" small class="mb-1"></b-spinner>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus product-counter-icon"
                viewBox="0 0 16 16">
                <path
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
        </button>
    </span>
</template>
<script>
import {BSpinner} from 'bootstrap-vue'
    export default {
  components: {
    BSpinner
  },
        props: {
            value: Object
        },
        data() {
            return {
                disabledDecrement: false,
                disabledIncrement: false,
            }
        },
        methods: {
            async decrement() {
              let item = {
                ...this.value
              };
              try {
                this.disabledDecrement = true;
                const response = await this.$store.dispatch('front/decrementCart', item);
                this.$root.notify(response.data.message, 'success')
              } catch (error) {
                console.log(error)
                if (error.message === 'NO_STOCK') {
                  this.$root.notify(' از این کالا تنها ' + item.variety.quantity + '  عدد موجود است ', 'warning')
                } else if (error.message === 'ZERO') {
                  return;
                } else {
                  this.$root.notify(error);
                }
              }
              this.disabledDecrement = false;
            },
            async increment() {
                let item = {
                    ...this.value
                };
                try {
                  this.disabledIncrement = true;
                  const response = await this.$store.dispatch('front/incrementCart', item);
                  this.$root.notify(response.data.message, 'success')
                } catch (error) {
                  if (error.message === 'NO_STOCK') {
                    this.$root.notify(' از این کالا تنها ' + item.new_variety.quantity + '  عدد موجود است ', 'warning')
                  } else {
                    this.$root.notify(error);
                  }
                }
                this.disabledIncrement = false;
            },
            newQuantity() {
                let item = {
                    ...this.value
                }
                window.swal({
                        title: "تعداد جدید را وارد کنید",
                        icon: null,
                        showCancelButton: true,
                        showConfirmButton: true,
                        // dangerMode: true,
                        buttons: ["انصراف", "تایید"],
                        content: {
                            element: "input",
                            attributes: {
                                placeholder: "تعداد جدید را وارد کنید",
                                type: "text",
                            },
                        },
                    })
                    .then(async (willSubmit) => {
                        if (willSubmit) {
                            if (+willSubmit >= item.variety.quantity + item.quantity) {
                                window.swal({
                                    title: '',
                                    showConfirmButton: true,
                                    text: 'تعداد درخواستی بیشتر از موجودی محصول می باشد',
                                    button: "باشه",
                                })
                            } else {
                                this.disabledIncrement = true
                                try {
                                  const response = await this.$store.dispatch('front/changeCartQuantity',
                                      {cartItem: item, newQuantity: +willSubmit});
                                  window.swal({
                                        icon: "success",
                                        showConfirmButton: true,
                                        text: response.data.message,
                                        button: "باشه",
                                    })
                                } catch (error) {
                                  if (error.message === 'NO_STOCK') {
                                    this.$root.notify(' از این کالا تنها ' + item.variety.quantity + '  عدد موجود است ', 'warning')
                                  } else if (error.message === 'ZERO') {
                                    return;
                                  } else {
                                    window.swal({
                                      title: 'خطا',
                                      showConfirmButton: true,
                                      text: error.response.data.message,
                                      button: "باشه",
                                    })
                                  }
                                }
                                this.disabledIncrement = false
                            }

                        }
                    })
                    .catch(() => {

                    })
            }
        }
    }
</script>
<style scoped>
    .quantity-btn{
        background: none !important;
    }
    /* @media screen and (max-width: 575px) {
      .product-counter-icon {
        width: 20px !important;
        height: 20px !important;
      }
    } */
</style>
<style>
  .in-cart-quantity .spinner-border-sm {
    height: 18px;
    width: 18px;
  }
</style>
